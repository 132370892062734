import { graphql, useStaticQuery } from "gatsby"

const usePosts = () => {
	const data = useStaticQuery(graphql`
		query Posts {
			allMarkdownRemark(
				limit: 5
				sort: { fields: [frontmatter___date], order: DESC }
				filter: { frontmatter: { template: { eq: "post" } } }
			) {
				nodes {
					frontmatter {
						title
						slug
						author
						tags
						categories
						date(formatString: "MMMM DD, YYYY")
						thumbnail {
							childImageSharp {
								fixed(width: 50, height: 50) {
									...GatsbyImageSharpFixed_withWebp
								}
							}
						}
					}
					excerpt
				}
			}
		}
	`)
	return data.allMarkdownRemark.nodes.map((post) => ({
		title: post.frontmatter.title,
		slug: post.frontmatter.slug,
		author: post.frontmatter.author,
		tags: post.frontmatter.tags,
		date: post.frontmatter.date,
		categories: post.frontmatter.categories,
		thumbnail: post.frontmatter.thumbnail,

		excerpt: post.excerpt,
	}))
}

export default usePosts
