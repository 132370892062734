import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

/*
 * // TODO:
 * - Animation beim Hover vl(https://www.framer.com/api/motion/examples/#variants)
 */
const tagsColorStore = {
	js: "#efd81d",
	jsFont: "#000000",
	css: "#016db4",
	cssFont: " #fff",
	react: "#5ed3f3",
	reactFont: "#212121",
	gatsby: "#704a99",
	gatsbyColor: "#fff",
	"styled-components": "#eead9b",
	"styled-componentsFont": "#000b09",
}

const PostSection = styled(Link)`
	display: flex;
	padding: 10px;
	align-items: center;
	margin: 40px 0;
	border-radius: 4px;
	&:hover {
		background-color: ${(props) => props.theme.bgLight};
	}

	@media screen and (${global.mediaDesktop}) {
		margin: 40px 0 0 -10px;
	}
`

const PostTitle = styled.h3`
	font-size: 1rem;
	font-weight: 500;
`

const PostMain = styled.div`
	display: flex;
	flex-direction: column;
`

const Thumbnail = styled(Img)`
	margin-right: 25px;
`

const PostMeta = styled.div`
	display: flex;
	align-items: flex-start;
	margin-top: 5px;
`
const MetaDate = styled.span`
	font-size: 0.8rem;
	margin-right: 5px;
`
const Tag = styled.span`
	font-size: 0.6rem;
	padding: 2px 5px;
	margin-right: 5px;
	border-radius: 2px;
	background-color: ${(props) =>
		tagsColorStore.hasOwnProperty(props.tag)
			? tagsColorStore[props.tag]
			: props.theme.bgLight};
	color: ${(props) =>
		tagsColorStore.hasOwnProperty(props.tag)
			? tagsColorStore[props.tag + "Font"]
			: props.theme.link};
`
const PostPreview = React.memo(({ post }) => {
	return (
		<PostSection to={"/articles/" + post.slug}>
			<Thumbnail
				alt={post.thumbnail.childImageSharp.fixed.originalName}
				fixed={post.thumbnail.childImageSharp.fixed}
			/>
			<PostMain>
				<PostTitle>{post.title}</PostTitle>
				<PostMeta>
					<MetaDate>{post.date}</MetaDate>
					{post.tags
						? post.tags.map((tag) => (
								<Tag key={tag} tag={tag}>
									{tag}
								</Tag>
						  ))
						: ""}
				</PostMeta>
			</PostMain>
		</PostSection>
	)
})

export default PostPreview
