import React from "react"
import styled from "styled-components"
import global from "../css/globalVariables"

const Box = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	&:nth-of-type(even) {
		margin: 25px 0;
	}
	@media screen and (${global.mediaDesktop}) {
		padding: 0 2rem;
		&:nth-of-type(even) {
			margin: initial;
			border-left: 1px solid ${(props) => props.theme.bgLight};
			border-right: 1px solid ${(props) => props.theme.bgLight};
		}
	}
`
const Title = styled.h4`
	margin-bottom: 10px;
	font-weight: 400;
	color: ${(props) => props.theme.link};
`
const Icon = styled.div`
	height: 100px;
	width: 100px;
`
const Info = styled.span`
	font-size: 0.7rem;
`
const WorkBox = (props) => {
	return (
		<Box>
			<Title>{props.title}</Title>
			<Icon>{props.icon}</Icon>
			<Info>{props.info}</Info>
		</Box>
	)
}

export default WorkBox
