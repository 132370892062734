import React from "react"
import styled from "styled-components"

const Modal = styled.div`
	border-radius: 4px;
	background-color: ${(props) => props.theme.link};
	color: ${(props) => props.theme.bg};
	padding: 5px;
	margin: 25px 0;
`

const ModalBox = ({ children }) => {
	return <Modal>{children}</Modal>
}

export default ModalBox
