import React from "react"
import styled, { css } from "styled-components"
import Github from "../assets/icons/github.svg"
import LinkedIn from "../assets/icons/linkedIn.svg"
import global from "../css/globalVariables"

const Aside = styled.aside`
	display: none;
	padding: 15px;
	background-color: ${(props) => props.theme.bgLight};
	border-radius: 4px;
	z-index: 1;
	max-width: 230px;

	@media screen and (${global.mediaDesktop}) {
		display: block;
	}
`
const Icon = css`
	height: 40px;
	width: 40px;
	fill: #fff;
	margin-right: 15px;
	z-index: 2;
`
const GithubIcon = styled(Github)`
	${Icon}
`
const LinkedInIcon = styled(LinkedIn)`
	${Icon}
`

const Profilbild = styled.img`
	height: auto;
	max-width: 200px;
	border-radius: 4px;
`

const SocialLink = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid ${(props) => props.theme.link};
	color: ${(props) => props.theme.link};
	position: relative;
	overflow: hidden;
	padding: 5px;
	transition: all 0.5s;
	background: none;
	z-index: 2;
	&::before {
		content: "";
		background-color: ${(props) => props.theme.link};
		transform: skew(45deg, 0);
		width: 0;
		height: 100%;
		position: absolute;
		top: 0px;
		left: -30px;
		transition: all 0.5s;
		z-index: 1;
	}
	&:hover {
		color: ${(props) => props.theme.bgLight};
	}
	&:hover::before {
		width: 150%;
		z-index: -1;
	}

	&:first-of-type {
		margin-bottom: 10px;
	}
`

const Social = () => {
	return (
		<Aside>
			<Profilbild
				src="https://avatars1.githubusercontent.com/u/20201125?s=460&v=4"
				alt="gianluca frongia"
			/>
			<p>Follow me on:</p>
			<SocialLink href="https://github.com/GianlucaFrongia">
				<GithubIcon /> Github{" "}
			</SocialLink>
			<SocialLink href="https://www.linkedin.com/in/gianluca-frongia-676843162/">
				<LinkedInIcon /> LinkedIn {""}
			</SocialLink>
		</Aside>
	)
}

export default Social
